.App {
  text-align: center;
  /* position: relative; */
  background-color: white;
  width: 100vw; /* 100% of viewport width */
  height: 100vh; /* 100% of viewport height */
  margin: 0; /* Remove any remaining margin */
  padding: 0; /* Remove any remaining padding */
  overflow-y: auto;
  overflow-x: auto;
}
.iframe {
  /* position: absolute;
  top: -60px; */
  left: 0;
  right: 0;
  border: none; /* Optionally remove iframe border */
  height: 100%;
  /* Set the height to cover the entire viewport minus 60px */
  /* height: calc(
  100vh + 60px
);  */
  width: 100%; /* 100% of viewport width */
  /* overflow-y: hidden; 
  overflow-x: hidden; */
  overflow: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  width: 100vw;
  height: 60px;
  position: absolute;
  top: -60px;
  left: 0;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
